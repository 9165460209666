import { type ApiAuthoriseUserSessionResponse } from "@again-enable-banking/api";

export const postConsentError = async (
  baseUrl: string,
  urlSearchParams: URLSearchParams,
  error: any
): Promise<ApiAuthoriseUserSessionResponse> => {
  if (error?.message && typeof error.message === "string") {
    urlSearchParams.append("error_message", error.message);
  }
  const response = await fetch(
    `${baseUrl}/user-authorisation/consent-error?${urlSearchParams.toString()}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};
