import type { ApiCheckAuthorisedResponse } from "@again-enable-banking/api/user-authorisation/check-authorised";

export const checkAuthorised = async (
  baseUrl: string,
  urlSearchParams: URLSearchParams
): Promise<ApiCheckAuthorisedResponse> => {
  const response = await fetch(
    `${baseUrl}/user-authorisation/check-authorised?${urlSearchParams.toString()}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};
