import type { ComponentChildren } from "preact";

type Props = {
  id: string;
  children: ComponentChildren;
};

export const AspspList = ({ id, children }: Props) => {
  return (
    <ul id={id} role="list" className="list-none divide-y my-4 pb-4">
      {children}
    </ul>
  );
};
