import { type ApiStartUserAuthorisationResponse } from "@again-enable-banking/api";
import type { ApiStartUserAuthorisationBody } from "@again-enable-banking/api/user-authorisation/start";

export const startUserSession = async (
  baseUrl: string,
  body: ApiStartUserAuthorisationBody
): Promise<ApiStartUserAuthorisationResponse> => {
  const response = await fetch(`${baseUrl}/user-authorisation/start`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};
