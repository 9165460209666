import type { AspspView } from "@again-enable-banking/api/schemas/userSessionSchema";

type Props = {
  aspsp: AspspView;
  delayIndex: number;
  onClick: () => any;
};

export const AspspItem = ({ aspsp, delayIndex, onClick }: Props) => {
  return (
    <li
      style={`--slide-delay:${Math.min(200, delayIndex * 25)}ms`}
      className={
        "animate-slidein opacity-0 cursor-pointer active:bg-slate-200 focus:bg-slate-200 transition-all focus:outline-none"
      }
      data-start-link-name={aspsp.name}
      data-start-link-country={aspsp.country}
      onClick={onClick}
      tabIndex={0}
    >
      <div className="flex items-center p-3 pointer-events-none select-none">
        <h3 className="flex-1">{aspsp.name}</h3>
        <div className="h-4 max-w-24">
          <img
            src={`${aspsp.logo}-/resize/500x/`}
            alt={aspsp.name}
            className="h-4 object-contain"
          />
        </div>
        <span className="w-4 h-4 leading-4 text-right after:content-['›']" />
      </div>
    </li>
  );
};
