import { useEffect, useRef, useState } from "preact/hooks";
import type { ApiStartUserAuthorisationSuccessfulResponse } from "@again-enable-banking/api/index";
import { waitMs } from "../utils/waitMs";
import { generateConsentHtml } from "../utils/generateConsentHtml";
import { ConsentDisplaySkeleton } from "./ConsentDisplaySkeleton";
import { injectAboutReadMoreButton } from "../utils/injectAboutReadMoreButton";
import { redirectToContinue } from "../utils/api/redirectToContinue";
import { ExternalBrowserWaiting } from "./ExternalBrowserWaiting";
import { dispatchOpenUrl } from "../utils/dispatchMessage";
import { makeContinueUrl } from "../utils/api/makeContinueUrl";

type Props = {
  baseUrl: string;
  response: ApiStartUserAuthorisationSuccessfulResponse | null | undefined;
  onError: (err: any) => any;
  onCancel: () => any;
};

export const elemId = "enablebanking-consent";

export const ConsentDisplay = ({
  baseUrl,
  response,
  onCancel,
  onError,
}: Props) => {
  const didMountFor = useRef("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [showExternalBrowserWaiting, setShowExternalBrowserWaiting] =
    useState(false);

  useEffect(() => {
    if (!response?.authorization_id) {
      return;
    }

    if (didMountFor.current === response.authorization_id) {
      // prevents double useEffect() in dev
      return;
    }

    if (containerRef.current == null) {
      return;
    }

    didMountFor.current = response.authorization_id;

    containerRef.current.innerHTML = generateConsentHtml(response, elemId);

    setTimeout(() => {
      const elem = document.getElementById(elemId);
      if (!elem) {
        console.error("Consent element not found");
        return;
      }

      /*
       event names:
      "error", if an error occurs,
      "ready", when the widget is fully loaded,
      "confirmed", after a user has confirmed the consent,
      "cancelled", if the “Cancel” button was pressed.
      */

      elem.addEventListener("ready", function (e) {
        console.log("ready", e);
        setShowSkeleton(false);
        containerRef.current!.classList.remove("opacity-0");
        setTimeout(injectAboutReadMoreButton, 0);
      });

      elem.addEventListener("error", function (e) {
        console.log("error", e);
        onError(e);
      });

      elem.addEventListener("confirmed", async function (e) {
        console.log("confirmed", e);
        containerRef.current!.classList.add("animate-slideout");
        await waitMs(300);
        const params = new URLSearchParams(document.location.search);
        if (response.is_external_browser) {
          dispatchOpenUrl(makeContinueUrl(baseUrl, params));
          setShowExternalBrowserWaiting(true);
        } else {
          redirectToContinue(baseUrl, params);
        }
      });

      elem.addEventListener("cancelled", async function (e) {
        console.log("cancelled", e);
        const aspspDiv = document.getElementById("selected-aspsp")!;
        aspspDiv.classList.add("animate-slideoutalt");
        onCancel();
      });
    }, 0);
  }, [response?.authorization_id]);

  return (
    <div className="animate-slideinalt" ref={wrapperRef}>
      {showSkeleton ? <ConsentDisplaySkeleton onCancel={onCancel} /> : null}
      {showExternalBrowserWaiting ? (
        <ExternalBrowserWaiting baseUrl={baseUrl} onCancel={onCancel} />
      ) : (
        <div id="consent-container" className="opacity-0" ref={containerRef} />
      )}
    </div>
  );
};
