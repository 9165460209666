type Props = {
  title: string;
};

export const AspspListHeader = ({ title }: Props) => {
  return (
    <h3 className="text-xs uppercase font-medium text-slate-500 bg-white border-b p-3 sticky top-0 block z-10 select-none pointer-events-none">
      {title}
    </h3>
  );
};
